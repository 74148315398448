/*================================================================================
	Item Name: mVMS - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: Syed Ali Naqi
	Author URL: http://www.themeforest.net/user/syedalinaqi
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "src/@core/scss/base/bootstrap-extended/variables";
.avatar.bg-none {
  background-color: #ffffff00 !important;
}

.img-sdg {
  max-height: 100px;
  max-width: 100px;
  align-self: center;
  border-radius: 10px;
}

.hoverShowMore {
  display: inline;
  visibility: hidden;
}

.HoveredForMore:hover {
  .hoverShowMore {
    display: inline;
    visibility: visible;
  }

  .showUnderline {
    text-decoration: underline;
  }
}

.range-slider-no-sign.noUi-horizontal {
  .noUi-handle {
    .noUi-tooltip {
      opacity: 0;
      transform: translate(-50%, -15%);

      &:before {
        content: ' ';
      }
    }

    &:active {
      .noUi-tooltip {
        opacity: 1;
      }
    }
  }
}

.unFocusInput {
  //box-sizing: border-box;
  border: 1px solid transparent !important;

  &:hover {
    border: 1px solid !important
  }
}

.showMore {
  display: inline;
}
.grid-view {
  .ecommerce-card {
    .item-company {
      display: flex !important;
    }

    .item-wrapper {
      width: 50%;

      &:first-child {
        align-self: center;
      }

      &:last-child {
        justify-content: end !important;

        img {
          margin-right: 10px;
          margin-bottom: 10px;


          &:hover {

          }
        }
      }
    }

    .shipping {
      display: flex !important;
      margin: 10px;
      align-items: center;
    }
  }
}
